import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { usePageTransitionContext } from "../../hook/usePageTransitionContext";
import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Company1b from "./Company1b";
import IceAgeSubLogo from "../../components/IceAgeSubLogo";
import ReadMoreCursor from "../../components/ReadMoreCursor";
import ScrollForMore from "../../components/ScrollForMore";
import CountUp, { useCountUp } from "react-countup";
import { useInView } from "react-intersection-observer";
import styles from "./index.module.scss";

const About = () => {
  const { dispatchPageTransition } = usePageTransitionContext();
  const { scroll } = useLocomotiveScroll();
  const { pathname } = useLocation();
  const sectionRef = useRef(null);
  const horizontalRef = useRef(null);
  const opacityRef = useRef(null);
  const cursorRef = useRef(null);
  const mobileAndTablet = useMediaQuery({ query: "(max-width: 1365px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();
  const [isSafari, setIsSafari] = useState(false)
  let scrollY = null;

  const { ref: poolsSupramax, inView: startCounterPoolsSupramax } = useInView({ threshold: 0.5, triggerOnce: true });
  const { ref: poolsPanamax, inView: startCounterPoolsPanamax } = useInView({ threshold: 0.5, triggerOnce: true });
  const { ref: poolsCapesize, inView: startCounterPoolsCapesize } = useInView({ threshold: 0.5, triggerOnce: true });

  useEffect(() => {
    if (scroll) scroll.scrollTo(0, { duration: 0 });
  }, []);

  useLayoutEffect(()=> {
    setIsSafari(navigator.vendor.startsWith('Apple'))
  }, [isSafari])

  // fleets animation
  useLayoutEffect(() => {
    let element = sectionRef?.current;
    let scrollingElement = horizontalRef?.current;

    let pinWrapWidth = scrollingElement.offsetWidth;

    let tl = gsap.timeline();

    setTimeout(() => {
      tl.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: () => `+=${pinWrapWidth - 850}`,
          scroller: ".App",
          scrub: true,
          pin: true,
          invalidateOnRefresh: true,
        },
        height: `${scrollingElement.scrollWidth}px`,
        ease: Power1.easeInOut,
      });

      tl.to(scrollingElement, {
        scrollTrigger: {
          trigger: scrollingElement,
          start: "center center",
          end: () => `+=${pinWrapWidth / 1.15}`,
          scroller: ".App",
          scrub: true,
          invalidateOnRefresh: true,
        },
        x: -pinWrapWidth,
        ease: Power1.easeInOut,
      });

      tl.to(opacityRef.current, {
        scrollTrigger: {
          trigger: scrollingElement,
          start: "top top",
          end: "bottom 20%",
          scroller: ".App",
          scrub: true,
          invalidateOnRefresh: true,
        },
        opacity: 0,
      });

      ScrollTrigger.refresh();
    }, 1000);

    return () => {
      tl.kill();
    };
  }, []);

  // console.log("startCounterPoolsSupramax " + startCounterPoolsSupramax);
  // useEffect(() => {
  //   // const [startCounterPoolsSupramax, setStartCounterPoolsSupramax] = useState(false);
  //   // const poolsSupramax = useRef(null);
  //   // const poolsPanamax = useRef(null);
  //   // const poolsCapesize = useRef(null);

  //   const observer = new IntersectionObserver((entries) => {
  //     const entry = entries[0];
  //     setStartCounterPoolsSupramax(entry.isIntersecting);
  //   });
  //   observer.observe(poolsSupramax.current);
  //   // console.log(poolsSupramax);
  // }, []);

  //cursor animation
  if (scroll && cursorRef.current) {
    scroll.on("scroll", (args) => {
      scrollY = args.delta.y;
      if (!mobileAndTablet && cursorRef.current) {
        cursorRef.current.style.transform = "scale(0)";
      }
    });
  }

  const displayCursor = () => {
    if (!mobileAndTablet) cursorRef.current.style.transform = "scale(1)";
    else return;
  };

  let y, x;

  const moveCursor = (e) => {
    if (!mobileAndTablet) {
      x = e.clientX - 25;
      y = e.clientY + scrollY - 25;

      cursorRef.current.style.left = `${x}px`;
      cursorRef.current.style.top = `${y}px`;
      cursorRef.current.style.transform = "scale(1)";
    } else return;
  };

  const hideCursor = () => {
    if (!mobileAndTablet) cursorRef.current.style.transform = "scale(0)";
    else return;
  };

  // services images
  let defaultPosition = { x: 0, y: 0 };

  const linkHoverStart = (e) => {
    if (!mobileAndTablet) {
      let target = e.nativeEvent.target.children[1].children[5];
      target.style.opacity = "1";
      target.style.transform = "scale3d(1, 1, 1)";
      if (defaultPosition.y !== 0) {
        if (defaultPosition.y > e.clientY) target.style.top = "-30%";
        if (defaultPosition.y < e.clientY) target.style.top = "-20%";
      } else {
        setTimeout(() => (defaultPosition.y = e.clientY), 200);
      }
      if (defaultPosition.x !== 0) {
        if (defaultPosition.x > e.clientX) target.style.right = "22%";
        if (defaultPosition.x < e.clientX) target.style.right = "18%";
      } else {
        defaultPosition.x = e.clientX;
      }
    } else return;
  };

  const linkHoverEnd = (e) => {
    let target = e.nativeEvent.target.children[1].children[5];

    if (!mobileAndTablet) {
      target.style.opacity = "0";
      target.style.transform = "scale3d(1, 1.3, 1)";
      defaultPosition.x = 0;
      defaultPosition.y = 0;
    } else return;
  };

  const cssScrollForMore = {
    position: "absolute",
    backgroundColor: "transparent",
    bottom: 34,
    zIndex: 10,
  };

  return (
    <>
      {!mobileAndTablet ? <ReadMoreCursor ref={cursorRef} /> : ""}
      <div className={styles.hero}>
        <video className={styles.background} src={isSafari ? "/content/video/ship.mov" : "/content/video/ship.mp4"} muted autoPlay loop playsInline></video>
        <h1 className={styles.title}>
          We Are <span>World Leader</span> in Global Vessel Management
        </h1>
        {isMobile ? <ScrollForMore style={cssScrollForMore} onClick={() => scroll.scrollTo("#about", { duration: 1 })} /> : ""}
      </div>
      <div id="about" className={styles.description}>
        <p>We are highly qualified and experienced vessel management company primarily in the dry cargo ocean transportation industry</p>
        <IceAgeSubLogo />
      </div>
      <div id="pools" className={styles.pools + " container"}>
        <h2>Pools</h2>
        <article
          ref={poolsSupramax}
          className={styles.poolsArticle}
          onClick={() => {
            dispatchPageTransition({ type: "pageTransitionVisible" });
            setTimeout(() => {
              navigate("/details/supramax");
            }, 1000);
          }}
          onMouseEnter={displayCursor}
          onMouseMove={moveCursor}
          onMouseLeave={hideCursor}
        >
          <div className={styles.poolsArticleLeft}>
            <button>Learn More</button>
            <h6>Supramax RSA</h6>
            <ul>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/supramax-weight.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsSupramax && (
                        <CountUp start={0.0} end={60.1} duration={2} separator=" " decimals={3} decimal="," delay={0.5} />
                      )}
                      <span>&nbsp;</span>
                    </div>
                    <p>Average DWT</p>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/supramax-calendar.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsSupramax && <CountUp start={0.0} end={6} duration={2} separator=" " delay={0.5} />}
                      <span>&nbsp;Years</span>
                    </div>
                    <p>Average age</p>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/supramax-size.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsSupramax && (
                        <CountUp start={0.0} end={50.0} duration={2} separator=" " decimals={3} decimal="," delay={0.5} />
                      )}
                      <span>&nbsp;-&nbsp;</span>
                      {startCounterPoolsSupramax && (
                        <CountUp start={0.0} end={64.0} duration={2} separator=" " decimals={3} decimal="," delay={0.5} />
                      )}
                    </div>
                    <p>vessel sizes</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.poolsArticleRight}>
            <picture>
              <source media="(max-width: 767px)" srcSet="/content/images/pools/mobile/1.jpg" alt="Supramax RSA"></source>
              <source media="(max-width: 1365px)" srcSet="/content/images/pools/tablet/1.jpg" alt="Supramax RSA"></source>
              <source media="(max-width: 1919px)" srcSet="/content/images/pools/laptop/1.jpg" alt="Supramax RSA"></source>
              <source media="(max-width: 2559px)" srcSet="/content/images/pools/desktop/1.jpg" alt="Supramax RSA"></source>
              <source media="(max-width: 3839px)" srcSet="/content/images/pools/2k/1.jpg" alt="Supramax RSA"></source>
              <img src="/content/images/pools/4k/1.jpg" alt="Supramax RSA" />
            </picture>
          </div>
        </article>
        <article
          ref={poolsPanamax}
          className={styles.poolsArticle}
          onClick={() => {
            dispatchPageTransition({ type: "pageTransitionVisible" });
            setTimeout(() => navigate("/details/panamax"), 1000);
          }}
          onMouseEnter={displayCursor}
          onMouseMove={moveCursor}
          onMouseLeave={hideCursor}
        >
          <div className={styles.poolsArticleLeft}>
            <button>Learn More</button>
            <h6>Panamax RSA</h6>
            <ul>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/supramax-weight.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsPanamax && (
                        <CountUp start={0.0} end={80.498} duration={2} separator=" " decimals={3} decimal="," delay={0.5} />
                      )}
                      <span>&nbsp;</span>
                    </div>
                    <p>Average DWT</p>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/supramax-calendar.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsPanamax && <CountUp start={0.0} end={10} duration={2} delay={0.5} />}
                      <span>&nbsp;Years</span>
                    </div>
                    <p>Average age</p>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/supramax-size.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsPanamax && <CountUp start={0.0} end={76.0} duration={2} separator=" " decimals={3} decimal="," delay={0.5} />}
                      <span>&nbsp;-&nbsp;</span>
                      {startCounterPoolsPanamax && <CountUp start={0.0} end={95.0} duration={2} separator=" " decimals={3} decimal="," delay={0.5} />}
                    </div>
                    <p>vessel sizes</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.poolsArticleRight}>
            <picture>
              <source media="(max-width: 767px)" srcSet="/content/images/pools/mobile/2.jpg" alt="Panamax RSA"></source>
              <source media="(max-width: 1365px)" srcSet="/content/images/pools/tablet/2.jpg" alt="Panamax RSA"></source>
              <source media="(max-width: 1919px)" srcSet="/content/images/pools/laptop/2.jpg" alt="Panamax RSA"></source>
              <source media="(max-width: 2559px)" srcSet="/content/images/pools/desktop/2.jpg" alt="Panamax RSA"></source>
              <source media="(max-width: 3839px)" srcSet="/content/images/pools/2k/2.jpg" alt="Panamax RSA"></source>
              <img src="/content/images/pools/4k/2.jpg" alt="Panamax RSA" />
            </picture>
          </div>
        </article>
        <article
          ref={poolsCapesize}
          className={styles.poolsArticle}
          onClick={() => {
            dispatchPageTransition({ type: "pageTransitionVisible" });
            setTimeout(() => navigate("/details/capesize"), 1000);
          }}
          onMouseEnter={displayCursor}
          onMouseMove={moveCursor}
          onMouseLeave={hideCursor}
        >
          <div className={styles.poolsArticleLeft}>
            <button>Learn More</button>
            <h6>Capesize RSA</h6>
            <ul>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/supramax-weight.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsCapesize && <CountUp start={0.0} end={3.7} duration={2} separator=" " decimals={3} decimal="," delay={0.5} />}
                      <span>&nbsp;m</span>
                    </div>
                    <p>Average DWT</p>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/supramax-calendar.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsCapesize && <CountUp start={0.0} end={9} duration={2} delay={0.5} />}
                      <span>&nbsp;Years</span>
                    </div>
                    <p>Average age</p>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div style={{ backgroundImage: "url('/content/icons/capesize-ship.svg')" }}></div>
                  <div>
                    <div>
                      {startCounterPoolsCapesize && <CountUp start={0.0} end={29} duration={2} delay={0.5} />}
                      &nbsp;
                    </div>
                    <p>vessels</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.poolsArticleRight}>
            <picture>
              <source media="(max-width: 767px)" srcSet="/content/images/pools/mobile/3.jpg" alt="Capesize RSA"></source>
              <source media="(max-width: 1365px)" srcSet="/content/images/pools/tablet/3.jpg" alt="Capesize RSA"></source>
              <source media="(max-width: 1919px)" srcSet="/content/images/pools/laptop/3.jpg" alt="Capesize RSA"></source>
              <source media="(max-width: 2559px)" srcSet="/content/images/pools/desktop/3.jpg" alt="Capesize RSA"></source>
              <source media="(max-width: 3839px)" srcSet="/content/images/pools/2k/3.jpg" alt="Capesize RSA"></source>
              <img src="/content/images/pools/4k/3.jpg" alt="Capesize RSA" />
            </picture>
          </div>
        </article>
      </div>
      <div id="fleets" ref={sectionRef} className={styles.fleetsContainer}>
        <div ref={opacityRef} className={styles.fleetTextContent}>
          <h2>Fleets</h2>
          <p>
            Ice Age currently <span style={{ backgroundImage: "url('/content/icons/fleet/fleet-manage.svg')" }}></span> manages a fleet of over{" "}
            <span style={{ backgroundImage: "url('/content/icons/fleet/fleet-vessel.svg')" }}></span> 200 dry cargo vessels at any point in time in
            the Handymax up to Capesize segments.
          </p>
        </div>
        <div ref={horizontalRef} className={styles.fleetContent}>
          <article
            onClick={() => {
              dispatchPageTransition({ type: "pageTransitionVisible" });
              setTimeout(() => navigate("/fleets/capesize"), 1000);
            }}
            className={styles.articleFleet}
            onMouseEnter={displayCursor}
            onMouseMove={moveCursor}
            onMouseLeave={hideCursor}
          >
            <div className={styles.articleTop}>
              <picture>
                <source media="(max-width: 767px)" srcSet="/content/images/fleets/mobile/1.jpg" alt="Aquacharm"></source>
                <source media="(max-width: 1919px)" srcSet="/content/images/fleets/laptop/1.jpg" alt="Aquacharm"></source>
                <source media="(max-width: 3839px)" srcSet="/content/images/fleets/2k/1.jpg" alt="Aquacharm"></source>
                <img src="/content/images/fleets/4k/1.jpg" alt="Aquacharm" />
              </picture>
            </div>
            <div className={styles.articleBottom}>
              <h5>Aquacharm</h5>
              <ul>
                <li>
                  <span>182,309</span>
                  <p>deadweight</p>
                </li>
                <li>
                  <span>Capesize</span>
                  <p>vessel type</p>
                </li>
                <li>
                  <span>2022</span>
                  <p>year built</p>
                </li>
              </ul>
            </div>
          </article>
          <article
            onClick={() => {
              dispatchPageTransition({ type: "pageTransitionVisible" });
              setTimeout(() => navigate("/fleets/newcastlemax"), 1000);
            }}
            className={styles.articleFleet}
            onMouseEnter={displayCursor}
            onMouseMove={moveCursor}
            onMouseLeave={hideCursor}
          >
            <div className={styles.articleTop}>
              <picture>
                <source media="(max-width: 767px)" srcSet="/content/images/fleets/mobile/2.jpg" alt="MP The Harrison"></source>
                <source media="(max-width: 1919px)" srcSet="/content/images/fleets/laptop/2.jpg" alt="MP The Harrison"></source>
                <source media="(max-width: 3839px)" srcSet="/content/images/fleets/2k/2.jpg" alt="MP The Harrison"></source>
                <img src="/content/images/fleets/4k/2.jpg" alt="MP The Harrison" />
              </picture>
            </div>
            <div className={styles.articleBottom}>
              <h5>MP The Harrison</h5>
              <ul>
                <li>
                  <span>208,283</span>
                  <p>deadweight</p>
                </li>
                <li>
                  <span>Newcastlemax</span>
                  <p>vessel type</p>
                </li>
                <li>
                  <span>2021</span>
                  <p>year built</p>
                </li>
              </ul>
            </div>
          </article>
          <article
            onClick={() => {
              dispatchPageTransition({ type: "pageTransitionVisible" });
              setTimeout(() => navigate("/fleets/panamax"), 1000);
            }}
            className={styles.articleFleet}
            onMouseEnter={displayCursor}
            onMouseMove={moveCursor}
            onMouseLeave={hideCursor}
          >
            <div className={styles.articleTop}>
              <picture>
                <source media="(max-width: 767px)" srcSet="/content/images/fleets/mobile/3.jpg" alt="Anna J"></source>
                <source media="(max-width: 1919px)" srcSet="/content/images/fleets/laptop/3.jpg" alt="Anna J"></source>
                <source media="(max-width: 3839px)" srcSet="/content/images/fleets/2k/3.jpg" alt="Anna J"></source>
                <img src="/content/images/fleets/4k/3.jpg" alt="Anna J" />
              </picture>
            </div>
            <div className={styles.articleBottom}>
              <h5>Anna J</h5>
              <ul>
                <li>
                  <span>82,279</span>
                  <p>deadweight</p>
                </li>
                <li>
                  <span>Panamax</span>
                  <p>vessel type</p>
                </li>
                <li>
                  <span>2022</span>
                  <p>year built</p>
                </li>
              </ul>
            </div>
          </article>
          <article
            onClick={() => {
              dispatchPageTransition({ type: "pageTransitionVisible" });
              setTimeout(() => navigate("/fleets/capesize"), 1000);
            }}
            className={styles.articleFleet}
            onMouseEnter={displayCursor}
            onMouseMove={moveCursor}
            onMouseLeave={hideCursor}
          >
            <div className={styles.articleTop}>
              <picture>
                <source media="(max-width: 767px)" srcSet="/content/images/fleets/mobile/4.jpg" alt="Navios Pollux"></source>
                <source media="(max-width: 1919px)" srcSet="/content/images/fleets/laptop/4.jpg" alt="Navios Pollux"></source>
                <source media="(max-width: 3839px)" srcSet="/content/images/fleets/2k/4.jpg" alt="Navios Pollux"></source>
                <img src="/content/images/fleets/4k/4.jpg" alt="Navios Pollux" />
              </picture>
            </div>
            <div className={styles.articleBottom}>
              <h5>Navios Pollux</h5>
              <ul>
                <li>
                  <span>180,727</span>
                  <p>deadweight</p>
                </li>
                <li>
                  <span>Capesize</span>
                  <p>vessel type</p>
                </li>
                <li>
                  <span>2008</span>
                  <p>year built</p>
                </li>
              </ul>
            </div>
          </article>
          <article
            onClick={() => {
              dispatchPageTransition({ type: "pageTransitionVisible" });
              setTimeout(() => navigate("/fleets"), 1000);
            }}
            className={styles.articleFleetLast}
            onMouseEnter={displayCursor}
            onMouseMove={moveCursor}
            onMouseLeave={hideCursor}
          >
            <div></div>
            <button>Learn More</button>
          </article>
        </div>
      </div>
      <div id="services" className={styles.services + " container"} onMouseEnter={displayCursor} onMouseMove={moveCursor} onMouseLeave={hideCursor}>
        <div className={styles.servicesLeft}>
          <h2>Services</h2>
        </div>
        <div className={styles.servicesRight}>
          <ul>
            <li
              onClick={() => {
                dispatchPageTransition({ type: "pageTransitionVisible" });
                setTimeout(() => navigate("/services/technical"), 1000);
              }}
              onMouseMove={linkHoverStart}
              onMouseLeave={linkHoverEnd}
            >
              <span>Technical</span>
              <picture>
                <source media="(max-width: 767px)" srcSet="/content/images/services/mobile/1.jpg" alt="technical link"></source>
                <source media="(max-width: 1365px)" srcSet="/content/images/services/laptop/1.jpg" alt="technical link"></source>
                <source media="(max-width: 1919px)" srcSet="/content/images/services/laptop/1.jpg" alt="technical link"></source>
                <source media="(max-width: 2559px)" srcSet="/content/images/services/desktop/1.jpg" alt="technical link"></source>
                <source media="(max-width: 3839px)" srcSet="/content/images/services/2k/1.jpg" alt="technical link"></source>
                <img src="/content/images/services/4k/1.jpg" alt="technical link" />
              </picture>
              <button>Learn More</button>
            </li>
            <li
              onClick={() => {
                dispatchPageTransition({ type: "pageTransitionVisible" });
                setTimeout(() => navigate("/services/commercial"), 1000);
              }}
              onMouseMove={linkHoverStart}
              onMouseLeave={linkHoverEnd}
            >
              <span>Commercial</span>
              <picture>
                <source media="(max-width: 767px)" srcSet="/content/images/services/mobile/2.jpg" alt="technical link"></source>
                <source media="(max-width: 1365px)" srcSet="/content/images/services/laptop/2.jpg" alt="technical link"></source>
                <source media="(max-width: 1919px)" srcSet="/content/images/services/laptop/2.jpg" alt="technical link"></source>
                <source media="(max-width: 2559px)" srcSet="/content/images/services/desktop/2.jpg" alt="technical link"></source>
                <source media="(max-width: 3839px)" srcSet="/content/images/services/2k/2.jpg" alt="technical link"></source>
                <img src="/content/images/services/4k/2.jpg" alt="technical link" />
              </picture>
              <button>Learn More</button>
            </li>
            <li
              onClick={() => {
                dispatchPageTransition({ type: "pageTransitionVisible" });
                setTimeout(() => navigate("/services/operation"), 1000);
              }}
              onMouseMove={linkHoverStart}
              onMouseLeave={linkHoverEnd}
            >
              <span>Operations</span>
              <picture>
                <source media="(max-width: 767px)" srcSet="/content/images/services/mobile/3.jpg" alt="technical link"></source>
                <source media="(max-width: 1365px)" srcSet="/content/images/services/laptop/3.jpg" alt="technical link"></source>
                <source media="(max-width: 1919px)" srcSet="/content/images/services/laptop/3.jpg" alt="technical link"></source>
                <source media="(max-width: 2559px)" srcSet="/content/images/services/desktop/3.jpg" alt="technical link"></source>
                <source media="(max-width: 3839px)" srcSet="/content/images/services/2k/3.jpg" alt="technical link"></source>
                <img src="/content/images/services/4k/3.jpg" alt="technical link" />
              </picture>
              <button>Learn More</button>
            </li>
            <li
              onClick={() => {
                dispatchPageTransition({ type: "pageTransitionVisible" });
                setTimeout(() => navigate("/services/logistics"), 1000);
              }}
              onMouseMove={linkHoverStart}
              onMouseLeave={linkHoverEnd}
            >
              <span>Logistics</span>
              <picture>
                <source media="(max-width: 767px)" srcSet="/content/images/services/mobile/4.jpg" alt="technical link"></source>
                <source media="(max-width: 1365px)" srcSet="/content/images/services/laptop/4.jpg" alt="technical link"></source>
                <source media="(max-width: 1919px)" srcSet="/content/images/services/laptop/4.jpg" alt="technical link"></source>
                <source media="(max-width: 2559px)" srcSet="/content/images/services/desktop/4.jpg" alt="technical link"></source>
                <source media="(max-width: 3839px)" srcSet="/content/images/services/2k/4.jpg" alt="technical link"></source>
                <img src="/content/images/services/4k/4.jpg" alt="technical link" />
              </picture>
              <button>Learn More</button>
            </li>
          </ul>
        </div>
      </div>
      <Company1b />
    </>
  );
};

export default About;
