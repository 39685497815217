import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import { TransitionProvider } from "./hoc/TransitionProvider";
import { MoveLogoProvider } from "./hoc/MoveLogoProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <TransitionProvider>
    <MoveLogoProvider>
      <Router>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </Router>
    </MoveLogoProvider>
  </TransitionProvider>
);
