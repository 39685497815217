const MoveLogoReducer = (state, action) => {
  switch (action.type) {
    case "heroVisible": {
      return {
        moveLogo: true,
      };
    }
    case "heroHidden": {
      return {
        moveLogo: false,
      };
    }
    default:
      return state;
  }
};

export default MoveLogoReducer;
