const PageTransitionModeReducer = (state, action) => {
  switch (action.type) {
    case "pageTransitionVisible": {
      return {
        pageTransitionAnimation: true,
      };
    }
    case "pageTransitionHidden": {
      return {
        pageTransitionAnimation: false,
      };
    }
    default:
      return state;
  }
};

export default PageTransitionModeReducer;
