import React from "react";
import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./index.module.scss";

const Preloader = () => {
  const containerRef = React.useRef(null);

  useLayoutEffect(() => {
    if (sessionStorage.hasOwnProperty("start", "preloadAnimation()")) {
      containerRef.current.remove();
    }
  }, []);

  useEffect(() => {
    const preloadEnd = (e) => {
      sessionStorage.setItem("start", "preloadAnimation()");
      const removePreloader = () => containerRef.current.remove();
      setTimeout(removePreloader, 2000);
      containerRef.current.classList.add(styles.hiddenContainer);
      setTimeout(() => preloadEnd(), 4000);
    };
    setTimeout(() => preloadEnd(), 4000);
  }, []);

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.block}>
        <div className={styles.water}>
          <span className={styles.wave}></span>
          <span className={styles.deepWater}></span>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
