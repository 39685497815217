import styles from "./index.module.scss";

const PanamaxTable = () => {
  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        <ul>
          <li>ship name</li>
          <li>deadweight</li>
          <li>vessel type</li>
          <li>year built</li>
          <li>shipyard</li>
          <li>flag</li>
        </ul>
      </div>
      <div className={styles.tbody}>
        <ul>
          <li>Anna J</li>
          <li>82,279</li>
          <li>Panamax</li>
          <li>2022</li>
          <li>Tsuneishi Cebu</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquabeauty</li>
          <li>82,023</li>
          <li>Panamax</li>
          <li>2020</li>
          <li>Sanoyas</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Aquagrace</li>
          <li>81,791</li>
          <li>Panamax</li>
          <li>2017</li>
          <li>Tsuneishi</li>
          <li>Singapore</li>
        </ul>
        <ul>
          <li>Aquaprima</li>
          <li>82,282</li>
          <li>Panamax</li>
          <li>2022</li>
          <li>YAMIC</li>
          <li>Italy</li>
        </ul>
        <ul>
          <li>Aquaruby</li>
          <li>81,982</li>
          <li>Panamax</li>
          <li>2022</li>
          <li>DACKS</li>
          <li>Singapore</li>
        </ul>
        <ul>
          <li>Bulk Japan</li>
          <li>82,951</li>
          <li>Panamax</li>
          <li>2006</li>
          <li>Tsuneishi</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>PS Cadiz</li>
          <li>82,256</li>
          <li>Panamax</li>
          <li>2010</li>
          <li>Tsuneishi</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>PS Framura</li>
          <li>76,832</li>
          <li>Panamax</li>
          <li>2014</li>
          <li>SPP</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Red Lily</li>
          <li>81,855</li>
          <li>Panamax</li>
          <li>2017</li>
          <li>Tsuneishi</li>
          <li>Malta</li>
        </ul>
      </div>
    </div>
  );
};

export default PanamaxTable;
