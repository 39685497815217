import React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMoveLogoContext } from "../../hook/useMoveLogoContext";
import { gsap, Power1, Bounce } from "gsap";
import { useMediaQuery } from "react-responsive";
import { usePageTransitionContext } from "../../hook/usePageTransitionContext";
import styles from "./index.module.scss";

const Layout = React.forwardRef((props, navRef) => {
  const [isOpen, setIsOpen] = useState(false);
  const [burger, setBurger] = useState(false);
  const [direction, setDirection] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const logoRef = useRef(null);

  const { scroll } = useLocomotiveScroll();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { dispatchPageTransition } = usePageTransitionContext();

  const { moveLogo, dispatchMoveLogo } = useMoveLogoContext();

  const hrefLi1 = useRef(null);
  const hrefLi2 = useRef(null);
  const hrefLi3 = useRef(null);
  const hrefLi4 = useRef(null);
  const hrefLi5 = useRef(null);
  const hrefLi6 = useRef(null);

  useEffect(() => {
    scroll?.on("scroll", (args) => {
      if (direction !== args.direction) {
        setDirection(args.direction);
      }
    });
  }, [scroll]);

  useEffect(() => {
    if (direction === "down") {
      logoRef.current.style.transform = "translateY(-300%)";
      if (!isMobile) {
        navRef.current.style.transform = "translateY(300%)";
      }
    } else if (direction === "up") {
      logoRef.current.style.transform = "translateY(0%)";
      if (!isMobile) {
        navRef.current.style.transform = "translateY(0%)";
      }
    }
  }, [direction]);

  // useEffect(() => {
  //   scroll?.update();
  // }, [scroll, pathname]);

  const clicking = () => {
    if (!burger) {
      setBurger(true);
    } else {
      setTimeout(() => setBurger(false), 1300);
    }
  };

  const handleNavigate = () => {
    if (pathname === "/about") {
      scroll?.scrollTo(0, { duration: 1 });
    } else {
      dispatchPageTransition({ type: "pageTransitionVisible" });
      setTimeout(() => navigate("/about"), 1000);
    }
  };

  useEffect(() => {
    if (burger) {
      if (isOpen) {
        setTimeout(() => setBurger(false), 1300);
      } else {
        setBurger(true);
        setTimeout(() => setBurger(false), 1300);
      }
    }
  }, [burger, isOpen]);

  useEffect(() => {
    if (scroll) {
      if (isMobile) {
        if (isOpen) {
          scroll.stop();
          gsap.to(navRef?.current, { duration: 0.5, y: 0, ease: Power1.easeInOut });
          gsap.to(hrefLi1?.current, { delay: 1.2, opacity: 1, duration: 0.5 });
          gsap.to(hrefLi2?.current, { delay: 1.2, opacity: 1, duration: 0.5 });
          gsap.to(hrefLi3?.current, { delay: 1.2, opacity: 1, duration: 0.5 });
          gsap.to(hrefLi4?.current, { delay: 1.2, opacity: 1, duration: 0.5 });
          gsap.to(hrefLi5?.current, { delay: 1.2, opacity: 1, duration: 0.5 });
          gsap.to(hrefLi6?.current, { delay: 1.2, opacity: 1, duration: 0.5 });
        } else {
          scroll.start();
          gsap.to(navRef?.current, { delay: 0.5, duration: 0.5, y: "100%", ease: Power1.easeInOut });
          gsap.to(hrefLi1?.current, { opacity: 0, duration: 0.5 });
          gsap.to(hrefLi2?.current, { opacity: 0, duration: 0.5 });
          gsap.to(hrefLi3?.current, { opacity: 0, duration: 0.5 });
          gsap.to(hrefLi4?.current, { opacity: 0, duration: 0.5 });
          gsap.to(hrefLi5?.current, { opacity: 0, duration: 0.5 });
          gsap.to(hrefLi6?.current, { opacity: 0, duration: 0.5 });
        }
      }
    }
  }, [scroll, isOpen]);

  useEffect(() => {
    if (pathname === "/about") {
      dispatchMoveLogo({ type: "heroHidden" });
    }
  }, [pathname]);

  return (
    <div className={isOpen ? styles.screen + " " + styles.screenActive : styles.screen} style={moveLogo ? { width: "50%" } : { width: "100%" }}>
      <div
        ref={logoRef}
        className={burger ? styles.header + " " + styles.headerDisabled : styles.header}
        style={isMobile && pathname !== "/about" ? { justifyContent: "center" } : { justifyContent: "space-between" }}
      >
        <img src="/content/icons/logotype.svg" alt="logotype" onClick={handleNavigate} />
        <button
          style={pathname === "/about" ? {} : { display: "none" }}
          className={styles.menuOpenBtn}
          onClick={() => {
            setIsOpen(!isOpen);
            clicking();
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <nav ref={navRef} style={pathname === "/about" ? {} : { display: "none" }}>
        <ul
          onClick={(e) => {
            if (e.target.tagName === "A") {
              setIsOpen(!isOpen);
            }
            if (e.target.tagName === "BUTTON") {
              setIsOpen(!isOpen);
            }
          }}
        >
          <li ref={hrefLi1}>
            <button onClick={() => scroll.scrollTo("#about", { duration: 1 })} data-text="About">
              About
            </button>
          </li>
          <li ref={hrefLi2}>
            <button onClick={() => scroll.scrollTo("#pools", { duration: 1 })} data-text="Pools">
              Pools
            </button>
          </li>
          <li ref={hrefLi3}>
            <button onClick={() => scroll.scrollTo("#fleets", { duration: 1 })} data-text="Fleets">
              Fleets
            </button>
          </li>
          <li ref={hrefLi4}>
            <button onClick={() => scroll.scrollTo("#services", { duration: 1 })} data-text="Services">
              Services
            </button>
          </li>
          <li ref={hrefLi5}>
            <button onClick={() => scroll.scrollTo("#contacts", { duration: 1 })} data-text="Contacts">
              Contacts
            </button>
          </li>
          <li ref={hrefLi6}>
            <a href="https://i01.ai/" target="_blank" rel="noreferrer">
              Developed by <span>The First Studios</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
});

export default Layout;
