import React from "react";
import styles from "./index.module.scss";

const ReadMoreCursor = React.forwardRef((attr, ref) => {

  return (
    <div {...attr} ref={ref} className={styles.container}>
      <div className={styles.content}>
        <img className={styles.arrow} src="/content/icons/arrow-right.svg" alt="no-content" />
        <img className={styles.text} src="/content/icons/read-more-text.svg" alt="no-content" />
      </div>
    </div>
  );
});

export default ReadMoreCursor;
