import { useReducer } from "react";
import { createContext } from "react";
import PageTransitionModeReducer from "./pageTransitionModeReducer";

const INITIAL_STATE_PAGE_TRANSITION = { pageTransitionAnimation: null };

export const PageTransitionContext = createContext(INITIAL_STATE_PAGE_TRANSITION);

export const TransitionProvider = ({ children }) => {
  const [statePageTransition, dispatchPageTransition] = useReducer(PageTransitionModeReducer, INITIAL_STATE_PAGE_TRANSITION);

  const valuePageTransition = {
    pageTransitionAnimation: statePageTransition.pageTransitionAnimation,
    dispatchPageTransition,
  };

  return <PageTransitionContext.Provider value={valuePageTransition}>
    {children}
  </PageTransitionContext.Provider>;
};
