import { useEffect, useRef } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import About from "./pages/About";
import Preloader from "./components/Preloader";
import Transition from "./components/Transition";
import Fleets from "./pages/Fleets";
import CapesizeTable from "./pages/Fleets/CapesizeTable";
import PanamaxTable from "./pages/Fleets/PanamaxTable";
import SupramaxTable from "./pages/Fleets/SupramaxTable";
import HandymaxTable from "./pages/Fleets/HandymaxTable";
import NewcastlemaxTable from "./pages/Fleets/NewcastlemaxTable";
import CapesizeDetails from "./pages/Details/CapesizeDetails";
import PanamaxDetails from "./pages/Details/PanamaxDetails";
import SupramaxDetails from "./pages/Details/SupramaxDetails";
import Commercial from "./pages/Services/Commercial";
import Logistics from "./pages/Services/Logistics";
import Operation from "./pages/Services/Operation";
import Technical from "./pages/Services/Technical";
import Footer from "./components/Footer";
import Layout from "./components/Layout";
import ScrollTriggerProxy from "./components/ScrollTriggerProxy";
import "locomotive-scroll/dist/locomotive-scroll.css";
import { useInView } from "react-intersection-observer";
import "./App.css";

const App = () => {
  const containerRef = useRef(null);
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { ref: footerRef, inView: hiddenNav } = useInView({ threshold: 0.1 });
  const navRef = useRef(null);

  useEffect(() => {
    (function getWindowSize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    })();
  }, []);

  useEffect(() => {
    if (pathname === "/about") {
      if (hiddenNav && !isMobile) {
        navRef.current.style.display = "none";
      } else {
        navRef.current.style.display = "block";
      }
    }
  }, [hiddenNav]);

  return (
    <>
      <Preloader />
      <Transition />
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          getDirection: true,
          getSpeed: true,
          multiplier: 1,
          tablet: {
            breakpoint: 1365,
            smooth: true,
            direction: "vertical",
          },
          smartphone: {
            breakpoint: 767,
            smooth: true,
            direction: "vertical",
            multiplier: 2,
          },
          // ... all available Locomotive Scroll instance options
        }}
        watch={
          [
            //...all the dependencies you want to watch to update the scroll EXCEPT the location one
          ]
        }
        containerRef={containerRef}
        location={pathname}
        // onLocationChange={scroll => scroll.scrollTo(0, { duration: 0, disableLerp: true })} // If you want to reset the scroll position to 0 for example
        onUpdate={() => {}} // Will trigger on
      >
        <ScrollTriggerProxy />
        <Layout ref={navRef} />
        <main data-scroll-container ref={containerRef} className="App">
          {/* {pathname === "/about" || (isMobile && !pathname.includes("/fleets")) ? <Layout /> : ""} */}
          <Routes>
            <Route path="/" element={<Navigate to="/about" replace />} />
            <Route path="*" element={<Navigate to="/about" replace />} />
            <Route path="about" element={<About />} />
            <Route path="fleets" element={<Fleets />}>
              <Route path="capesize" element={<CapesizeTable />} />
              <Route path="panamax" element={<PanamaxTable />} />
              <Route path="supramax" element={<SupramaxTable />} />
              <Route path="handymax" element={<HandymaxTable />} />
              <Route path="newcastlemax" element={<NewcastlemaxTable />} />
            </Route>
            <Route path="details/capesize" element={<CapesizeDetails />} />
            <Route path="details/panamax" element={<PanamaxDetails />} />
            <Route path="details/supramax" element={<SupramaxDetails />} />
            <Route path="services/commercial" element={<Commercial />} />
            <Route path="services/logistics" element={<Logistics />} />
            <Route path="services/operation" element={<Operation />} />
            <Route path="services/technical" element={<Technical />} />
          </Routes>
          <Footer ref={footerRef} />
        </main>
      </LocomotiveScrollProvider>
    </>
  );
};

export default App;
