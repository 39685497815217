import { useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePageTransitionContext } from "../../../hook/usePageTransitionContext";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { gsap, Power2, Bounce } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import IceAgeSubLogo from "../../../components/IceAgeSubLogo";
import ScrollForMore from "../../../components/ScrollForMore";
import styles from "../index.module.scss";
const Logistics = () => {
  const navigate = useNavigate();
  const { scroll } = useLocomotiveScroll();

  const { dispatchPageTransition } = usePageTransitionContext();

  const contentRef = useRef(null);
  const contentBlock1Ref = useRef(null);
  const contentBlock2Ref = useRef(null);
  const contentBlock3Ref = useRef(null);
  const contentBlock4Ref = useRef(null);
  const contentBlock5Ref = useRef(null);
  const contentBlock6Ref = useRef(null);

  useEffect(() => {
    if (scroll) setTimeout(() => scroll.scrollTo(0, { duration: 0 }), 100);
  }, []);

  useEffect(() => {
    if (scroll) {
      const element = scroll?.el;

      let tl = gsap.timeline({
        defaults: {
          x: 0,
          y: 0,
          skewX: 0,
          duration: 0.5,
          ease: Bounce,
          opacity: 1,
        },
        scrollTrigger: {
          trigger: contentRef.current,
          scroller: element,
          start: "top top",
          toggleActions: "play pouse none none",
          // scrub: true,
          // end: () => `+=${contentRef.current.offsetHeight}`,
          // pin: true,
          // invalidateOnRefresh: false,
        },
      });

      tl.to(contentBlock1Ref.current, {});
      tl.to(contentBlock2Ref.current, {});
      tl.to(contentBlock3Ref.current, {});
      tl.to(contentBlock4Ref.current, {});
      tl.to(contentBlock5Ref.current, {});
      tl.to(contentBlock6Ref.current, {});

      return () => tl.kill();
    }
  }, [scroll]);

  return (
    <>
      <button
        className={styles.back}
        onClick={() => {
          dispatchPageTransition({ type: "pageTransitionVisible" });
          setTimeout(() => navigate(-1), 1000);
        }}
      ></button>
      <div className={styles.hero}>
        <div className={styles.left}>
          <img className={styles.logotype} src="/content/icons/logotype.svg" alt="logotype" />
          <div className={styles.left_title}>
            <h2>Logistics Service</h2>
          </div>
          <ScrollForMore onClick={() => scroll.scrollTo("#contentLogistics", { duration: 1 })} />
        </div>
        <div className={styles.right}>
          <picture>
            <source media="(max-width: 767px)" srcSet="/content/images/secondary_pages/services/mobile/4.jpg" alt="content"></source>
            <source media="(max-width: 1919px)" srcSet="/content/images/secondary_pages/services/laptop/4.jpg" alt="content"></source>
            <source media="(max-width: 2559px)" srcSet="/content/images/secondary_pages/services/desctop/4.jpg" alt="content"></source>
            <source media="(max-width: 3839px)" srcSet="/content/images/secondary_pages/services/2k/4.jpg" alt="content"></source>
            <img src="/content/images/secondary_pages/services/4k/4.jpg" alt="content" />
          </picture>
        </div>
      </div>
      <div ref={contentRef} id="subtitleLogistics" className={styles.subtitle}>
        <p>Our aim is to reduce the freight costs, thereby enhancing the competitiveness of our end-users and suppliers</p>
        <IceAgeSubLogo />
      </div>
      <div className={styles.contentLogisticWrapper + " container"}>
        <div id="contentLogistics" className={styles.contentLogistic + " container"}>
          <div className={styles.textContent}>
            <h5>The Multi-purpose Coastal Vessel</h5>
            <p>
              One part of our tailored solutions has been our involvement in the design and creation of the Multi-purpose Coastal Vessel (MCV). The
              MCV has been chosen as the preferred solution by a foreign consortium and Vietnamese State Authorities. The MCV is capable of
              discharging cargo from all bulk carriers up to capesize
            </p>
          </div>
          <ul>
            <li ref={contentBlock1Ref}>
              <span></span>Flexible 3 months exit notice
            </li>
            <li ref={contentBlock2Ref}>
              <span></span>High Manoeuvrability: Bow & Stern Thrusters – no need for tug assistance
            </li>
            <li ref={contentBlock3Ref}>
              <span></span>A Self-unloading System
            </li>
            <li ref={contentBlock4Ref}>
              <span></span>Tailored Design based on customers requirements
            </li>
            <li ref={contentBlock5Ref}>
              <span></span>A Shallow draft
            </li>
            <li ref={contentBlock6Ref}>
              <span></span>Certified for international trading & open sea transshipment operations
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Logistics;
