import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { usePageTransitionContext } from "../../../hook/usePageTransitionContext";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { gsap, Power2, Bounce } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import IceAgeSubLogo from "../../../components/IceAgeSubLogo";
import ScrollForMore from "../../../components/ScrollForMore";
import styles from "../index.module.scss";

const Operation = () => {
  const navigate = useNavigate();
  const { scroll } = useLocomotiveScroll();

  const { dispatchPageTransition } = usePageTransitionContext();

  const contentRef = useRef(null);
  const contentBlock1Ref = useRef(null);
  const contentBlock2Ref = useRef(null);

  const subtitleRef = useRef(null);

  useEffect(() => {
    if (scroll) setTimeout(() => scroll.scrollTo(0, { duration: 0 }), 100);
  }, []);

  useEffect(() => {
    if (scroll) {
      const element = scroll?.el;

      function processAnimation() {
        let tl = gsap.timeline({
          defaults: {
            duration: 1,
            x: 0,
            y: 0,
            skewX: 0,
          },
          scrollTrigger: {
            trigger: subtitleRef.current,
            scroller: element,
            start: "bottom bottom",
            end: () => `+=${contentRef.current.offsetHeight}`,
            // scrub: true,
            // pin: true,
            invalidateOnRefresh: true,
            toggleActions: "play pouse none none",
          },
        });

        tl.to(contentBlock1Ref.current, {});
        tl.to(contentBlock2Ref.current, {});

        return () => tl.kill();
      }
      processAnimation();
      ScrollTrigger.refresh();
    }
  }, [scroll]);
  return (
    <>
      <button
        className={styles.back}
        onClick={() => {
          dispatchPageTransition({ type: "pageTransitionVisible" });
          setTimeout(() => navigate(-1), 1000);
        }}
      ></button>
      <div className={styles.hero}>
        <div className={styles.left}>
          <img className={styles.logotype} src="/content/icons/logotype.svg" alt="logotype" />
          <div className={styles.left_title}>
            <h2>Operation Service</h2>
          </div>
          <ScrollForMore onClick={() => scroll.scrollTo("#subtitleOperation", { duration: 1 })} />
        </div>
        <div className={styles.right}>
          <picture>
            <source media="(max-width: 767px)" srcSet="/content/images/secondary_pages/services/mobile/2.jpg" alt="content"></source>
            <source media="(max-width: 1919px)" srcSet="/content/images/secondary_pages/services/laptop/2.jpg" alt="content"></source>
            <source media="(max-width: 2559px)" srcSet="/content/images/secondary_pages/services/desctop/2.jpg" alt="content"></source>
            <source media="(max-width: 3839px)" srcSet="/content/images/secondary_pages/services/2k/2.jpg" alt="content"></source>
            <img src="/content/images/secondary_pages/services/4k/2.jpg" alt="content" />
          </picture>
        </div>
      </div>
      <div ref={subtitleRef} id="subtitleOperation" className={styles.subtitle}>
        <p>
          We ensure that once our clients’ vessels have been fixed by our Commercial Management team, our Operational Management team handles all the
          Post Fixing Activities necessary
        </p>
        <IceAgeSubLogo />
      </div>
      <div ref={contentRef} id="containerOperation" className={styles.content + " container"}>
        <div className={styles.content_container}>
          <article ref={contentBlock1Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/insurance.svg')" }}></div>
            <h6>Insurance & Claims Management</h6>
            <p>
              Our team specialises in all aspects of marine insurance, ship operations and dispute resolution ensuring our clients have the
              appropriate cover and peace of mind
            </p>
          </article>
          <article ref={contentBlock2Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/bunkers.svg')" }}></div>
            <h6>Bunkers</h6>
            <p>
              As part of our Operational Management team, we have full knowledge of the bunker markets covering both the purchase of bunkers and the
              hedging. We utilise the hedging of bunkers as part of our risk management strategy
            </p>
          </article>
        </div>
      </div>
    </>
  );
};

export default Operation;
