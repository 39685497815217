import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./index.module.scss";

const Video = React.lazy(() => import("../Video"));

const contacts = [
  // {
  //   address: `160 Robinson Road, #14-04 Singapore 
  //   Business Federation Centre, 068914`,
  //   addressLink: "https://goo.gl/maps/vYp17XBXc5Sq76kB6",
  //   label: "Singapore",
  //   img: "/content/icons/singapore.svg",
  // },
  {
    address: `12/F, Tower 3, 33 Canton Road,
    Tsim Sha Tsui, Kowloon`,
    addressLink: "https://goo.gl/maps/PJmrpWfVc1SBMEqi9",
    label: "Hong kong",
    img: "/content/icons/honk-kong.svg",
  },
  {
    address: `17th Floor, Building 4, No. 20
    Xiguan Road, Changping District`,
    addressLink: "https://goo.gl/maps/BLZH89SxcEFqQshv6",
    label: "China",
    img: "/content/icons/china.svg",
  },
  {
    address: `Westminster Tower, 3 Albert
    Embankment, London, SE1 7SP`,
    addressLink: "https://goo.gl/maps/HubWFdUC7B2wB9JX9",
    label: "UK",
    img: "/content/icons/united-kingdom.svg",
  },  
  {
    address: `1717 Pennsylvania Avenue, 10th Floor, NW, Washington, DC 20006`,
    addressLink: "https://maps.app.goo.gl/cSE8nBGRgosyWjhBA",
    label: "USA",
    img: "/content/icons/united-states.svg",
  },
];

const Footer = React.forwardRef((props, footerRef) => {
  const [selectedContent, setSelectedContent] = useState(contacts[0]);
  const mobileAndTablet = useMediaQuery({ query: "(max-width: 1365px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const linkHoverStart = (e) => {
    const pos = e.target.getBoundingClientRect();
    const mx = e.clientX - pos.left - pos.width / 2;
    const my = e.clientY - pos.top - pos.height / 2;

    if (!mobileAndTablet) e.target.style.transform = "translate(" + mx * 0.15 + "px, " + my * 0.3 + "px)";
    else return;
  };

  const linkHoverEnd = (e) => {
    if (!mobileAndTablet) e.target.style.transform = "translate3d(0px, 0px, 0px)";
    else return;
  };

  return (
    // <footer ref={navRef} {...attr} id="contacts" className={styles.footer}>
    <footer ref={footerRef} id="contacts" className={styles.footer}>
      <Video />
      <div className={styles.content + " container"} style={isMobile ? {maxWidth: "100%"} : {}}>
        <h2 className={styles.title}>Contacts</h2>
        <div className={styles.bottom}>
          {/* <h5 className={styles.companyName}>Ice Age Beijing Technology Co., Ltd</h5> */}
          <div  className={styles.btns}>
            {contacts.map((contact, idx) => (
              <button 
                key={idx + "contactBtn"}
                className={contact === selectedContent ? styles.activeBtn : ""}
                onMouseMove={linkHoverStart}
                onMouseLeave={linkHoverEnd}
                onClick={() => setSelectedContent(contact)}>{contact.label}</button>))}

          </div>
          <address className={styles.address}>
            <AnimatePresence mode="wait">
              <motion.a
                href={selectedContent.addressLink}
                target="_blank"
                rel="noreferrer"
                key={selectedContent ? selectedContent.label : "empty"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {selectedContent.address}
              </motion.a>
            </AnimatePresence>
          </address>
          <div className={styles.linksBlock}>
            <div className={styles.links}>
              <a onMouseMove={linkHoverStart} onMouseLeave={linkHoverEnd} href="tel:+447889888880" className={styles.link}>
                +44 7889 88888 0
              </a>
              <a onMouseMove={linkHoverStart} onMouseLeave={linkHoverEnd} href="tel:+447566000007" className={styles.link}>
                +44 75 66 000 007
              </a>
              <a onMouseMove={linkHoverStart} onMouseLeave={linkHoverEnd} href="mailto:i@iceage.ai" className={styles.link}>
                i@iceage.ai
              </a>
            </div>
            <a
              onMouseMove={linkHoverStart}
              onMouseLeave={linkHoverEnd}
              href="https://i01.ai"
              target="_blank"
              rel="noreferrer"
              className={styles.firstStudioLink}
            >
              Developed by The First Studios
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
